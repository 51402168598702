.error-container {
  height: 75vh;
  color: var(--main-color-1);
  text-align: center;
}

.error-code {
  font-size: 288px;
  font-weight: 700;
}

.error-msg {
  font-size: 36px;
  font-weight: 500;
}

.error-back-home {
  font-size: 18px;
  font-weight: 500;
  margin-top: 50px;
  color: var(--main-color-1);
  text-decoration: none;
}
