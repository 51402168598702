.about-main-banner {
  border-radius: 25px;
  width: 100%;
  height: 15vh;
  margin: 3vh 0;
}

.about-accordions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-accordion {
  width: 80%;
  margin-bottom: 3rem;
}
