.home-main-banner {
  border-radius: 25px;
  width: 100%;
  height: 15vh;
  margin: 3vh 0;
}

.main-banner-filter {
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-banner-text {
  font-size: 32px;
}

.cards-container {
  background-color: #f6f6f6;
  border-radius: 25px;
  padding: 60px;
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;
}
