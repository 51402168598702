.card-container {
  color: white;
  text-decoration: none;
}

.card {
  height: 340px;
  width: 340px;
  background-color: var(--main-color-1);
  border-radius: 10px;
}

.card-filter {
  height: 100%;
  border-radius: 10px;

  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.5)
  );
  display: flex;
  align-items: flex-end;
}

.card-title {
  margin: 0 0 15px 15px;
}
